<template>
  <div class="st-container" :class="{'up-open': upOpenCase}">
    <v-select
      v-model="selected"
      :options="destinationOptions"
      dir="ltr"
      :clearable="false"
      :placeholder="$t('search-tab.choose-destination')"
      @option:selecting="selecting"
      @search:focus="deselecting"
      class="style-chooser"
    >
    <template #selected-option="{ label }">
      <div style="display: flex; align-items: baseline;">
        <span style="margin-right:10px;">
          <i class="fa fa-plane" aria-hidden="true"></i>
        </span>
        {{ label }}
      </div>
    </template>
    <template v-slot:option="{ label }">
      <span style="margin-right:10px;font-size:1.5rem;">
          <i class="fa fa-map-marker-alt" aria-hidden="true"></i>
      </span>
      {{ label }}
    </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'Select',
  components: {
    vSelect,
  },
  props: {
    type: {
      type: String,
      default: 'tabs',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
      currentPage: 'GET_CURRENT_PAGE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
    }),
    stateSelected: {
      get() {
        const ts = this.$store.getters.GET_SEARCH_CONTENT.dest;
        return ts || this.destinationOptions[0];
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['dest', value]);
      },
    },
    upOpenCase() {
      const { isOdysseySite, currentPage, device } = this;
      return isOdysseySite && currentPage !== 'search-result' && device === 'desktop';
    },
  },
  watch: {
    selected() {
      this.$emit('changeDestination', this.selected);
      this.stateSelected = this.selected;
    },
    stateSelected() {
      this.selected = this.stateSelected;
    },
    lang() {
      this.makeSelectOption();
    },
  },
  data() {
    return {
      selected: null,
      destinationOptions: [],
      query: this.$route.query,
    };
  },
  mounted() {
    this.makeSelectOption();
  },
  methods: {
    makeSelectOption() {
      this.destinationOptions = [];
      this.list.forEach((item, inx) => {
        this.destinationOptions.push({
          id: inx,
          value: item.code,
          label: item.nameTranslations[this.lang],
        });
      });
      const qs = (this.query.city) ? this.query.city : '';
      this.selected = (qs) ? this.destinationOptions.find((elm) => elm.value === qs) : this.destinationOptions[0];
    },
    selecting() {
      this.$emit('optionSelect', true);
    },
    deselecting() {
      this.$emit('optionSelect', false);
    },
  },
};
</script>
<style scoped>
.st-container {
  overflow: visible;
}
</style>
<style>
.st-container.up-open .vs__dropdown-menu {
  top: auto;
  bottom: 50px;
}
input[type=search]{
  margin: 0;
  padding: 0;
  border: 0;
}
.st-container .vs--searchable .vs__dropdown-toggle {
  border-radius: 50px;
  padding: 0.575rem 1.35rem;
  height: auto;
}
.vs__selected{
  width: max-content;
}

.style-chooser .vs__dropdown-menu li {
  height: 45px;
  line-height: 35px;
  transition: 0.2s;
}

.vs__dropdown-option {
    color: #333 !important;
}

</style>
